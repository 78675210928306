import { ref, computed, watch } from 'vue'

import { GET_SUPPORTED_NETWORKS } from '#queries/GetSupportedNetworks'
import type { GetSupportedNetworks, GetSupportedNetworks_getSupportedNetworks } from '#graphql/types/GetSupportedNetworks'

import { useQuery } from '#composables/use-apollo'

const {
  onResult: getSupportedNetworkOnResult,
  error: getSupportedNetworksError,
  loading: getSupportedNetworksLoading,
} = useQuery<GetSupportedNetworks, never>(GET_SUPPORTED_NETWORKS)

const supportedNetworks = ref<GetSupportedNetworks_getSupportedNetworks | never>()
const chains = computed(() => supportedNetworks.value?.chains || null)
const chainIds = computed(() => supportedNetworks.value?.chains?.map(chain => chain?.chainId) || null)
const defaultChainId = computed(() => supportedNetworks.value?.defaultChainId || null)

getSupportedNetworkOnResult(async (result) => {
  if (result.data?.getSupportedNetworks && result.data.getSupportedNetworks.chains.length) {
    supportedNetworks.value = result.data?.getSupportedNetworks
  }
})

const isReady = new Promise(resolve => {
  watch(getSupportedNetworksLoading, (newVal) => {
    if (!newVal) {
      resolve(true)
    }
  }, {
    immediate: true,
  })
})

export function useSupportedNetworks () {
  return {
    getSupportedNetworksError,
    getSupportedNetworksLoading,
    chains,
    chainIds,
    defaultChainId,
    isReady,
  }
}
