import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import type { Ref } from 'vue'
import type { Snack, SnackOptions } from './types'


/**
 * All the snacks currently in the snackbar.
 */
const snacks: Ref<Snack[]> = ref ([])


/**
 * Adds a new snack to the snackbar.
 */
const addSnack = (options: SnackOptions) => {
  const defaults = {
    id: uuidv4(),
    time: 5000,
  }

  // Extend the defaults with the supplied options.
  const snack: Snack = Object.assign({}, defaults, options)

  // Create a new timer.
  snack.timer = setTimeout(() => removeSnack(snack.id), snack.time)

  // Add the new snack.
  snacks.value.push(snack)
}


/**
 * Removes a snack from the snackbar.
 */
const removeSnack = (id: string) => {
  snacks.value = snacks.value.filter(snack => snack.id !== id)
}

export default function useSnackbar () {
  return {
    snacks,
    addSnack,
    removeSnack,
  }
}
