<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="group"
  >
    <rect
      x="1"
      y="1"
      width="38"
      height="38"
      rx="19"
      stroke="black"
      fill="white"
      stroke-width="2"
      class="transition-colors duration-300 fill-white group-hover:fill-black group-hover:stroke-black"
    />
    <path
      d="M26 14L14 26"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="transition-colors duration-300 group-hover:stroke-white"
    />
    <path
      d="M14 14L26 26"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="transition-colors duration-300 group-hover:stroke-white"
    />
  </svg>
</template>
