export const getChainInfo = (chainId: number | null) => {
  switch (chainId) {
    case 1:
      return {
        id: chainId,
        name: 'Ethereum Mainnet',
        displayName: 'Ethereum',
        url: '//etherscan.io',
        currency: 'ETH',
        decimals: 5,
        currencyConversion: 'ethPrice',
        scannerName: 'Etherscan',
      }
    case 5:
      return {
        id: chainId,
        name: 'Goerli Test Network',
        displayName: 'Ethereum',
        url: '//goerli.etherscan.io',
        currency: 'ETH',
        decimals: 5,
        currencyConversion: 'ethPrice',
        scannerName: 'Etherscan',
      }
    case 11155111:
      return {
        id: chainId,
        name: 'Sepolia Test Network',
        displayName: 'Ethereum',
        url: '//sepolia.etherscan.io',
        currency: 'ETH',
        decimals: 5,
        currencyConversion: 'ethPrice',
        scannerName: 'Etherscan',
      }
    case 137:
      return {
        id: chainId,
        name: 'Polygon Mainnet',
        displayName: 'Polygon',
        url: '//polygonscan.com',
        currency: 'MATIC',
        decimals: 2,
        currencyConversion: 'maticPrice',
        scannerName: 'polygonscan',
      }
    case 80001:
      return {
        id: chainId,
        displayName: 'Polygon',
        name: 'Mumbai Test Network',
        url: '//mumbai.polygonscan.com',
        currency: 'MATIC',
        decimals: 2,
        currencyConversion: 'maticPrice',
        scannerName: 'polygonscan',
      }
    case 80002:
      return {
        id: chainId,
        displayName: 'Polygon',
        name: 'Amoy Test Network',
        url: '//oklink.com/amoy',
        currency: 'MATIC',
        decimals: 2,
        currencyConversion: 'maticPrice',
        scannerName: 'polygonscan',
      }
    case 84532:
      return {
        id: chainId,
        displayName: 'Base',
        name: 'Base Sepolia Test Network',
        url: '//sepolia.basescan.org',
        currency: 'ETH',
        decimals: 5,
        currencyConversion: 'ethPrice',
        scannerName: 'basescan',
      }
    case 8453:
      return {
        id: chainId,
        displayName: 'Base',
        name: 'Base',
        url: '//basescan.org',
        currency: 'ETH',
        decimals: 5,
        currencyConversion: 'ethPrice',
        scannerName: 'basescan',
      }
    default:
      return {
        id: chainId,
        name: 'Unknown',
        displayName: 'Unknown',
        url: '',
        currency: '',
        decimals: 2,
        scannerName: 'Unknown',
      }
  }
}
