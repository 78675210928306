const Contact = () => import('#pages/contact/contact.vue')

export default {
  path: '/contact',
  name: 'contact',
  component: Contact,
  meta: {
    title: 'contact',
  },
}
