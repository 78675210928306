const Feed = () => import('#pages/feed/feed.vue')
const About = () => import('#pages/root/about.vue')
const Create = () => import('#pages/root/create.vue')
const Collect = () => import('#pages/root/collect.vue')
const TermsOfService = () => import('#pages/root/termsOfService.vue')
const PrivacyPolicy = () => import('#pages/root/privacyPolicy.vue')
const Genesis = () => import('#pages/root/genesis.vue')
const DigitalFashionDesigner = () => import('#pages/root/digital-fashion-designer.vue')
const PageNotFound = () => import('#pages/root/page-not-found.vue')
const Home = () => import('#pages/root/home.vue')
const TwitterSignIn = () => import('#pages/root/twitter-sign-in.vue')

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Limited edition digi-physical drops you won’t find anywhere else.',
    },
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'about',
    },
    component: About,
  },
  {
    path: '/create',
    name: 'create',
    meta: {
      title: 'create',
    },
    component: Create,
  },
  {
    path: '/collect',
    name: 'collect',
    meta: {
      title: 'collect',
    },
    component: Collect,
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    meta: {
      title: 'terms of service',
    },
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: {
      title: 'privacy policy',
    },
    component: PrivacyPolicy,
  },
  {
    path: '/genesis',
    name: 'genesis',
    meta: {
      title: 'Genesis Token',
    },
    component: Genesis,
  },
  {
    path: '/digital-fashion-designer',
    name: 'digitalFashionDesigner',
    meta: {
      title: 'Digital Fashion Designer',
    },
    component: DigitalFashionDesigner,
  },
  {
    path: '/page-not-found',
    name: 'pageNotFound',
    meta: {
      title: 'Page Not Found',
    },
    component: PageNotFound,
  },
  {
    path: '/feed',
    name: 'feed',
    meta: {
      title: 'Feed',
    },
    component: Feed,
  },
  {
    path: '/twitter-sign-in',
    name: 'twitterSignIn',
    meta: {
      title: 'Twitter Sign In',
    },
    component: TwitterSignIn,
  },
]
