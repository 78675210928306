import { ref } from 'vue'

const isOpen = ref(false)
const isCreatePostAvailable = ref(false)

export default function useCreatePost () {
  return {
    isOpen,
    isCreatePostAvailable,
  }
}
