import { gql } from '@apollo/client/core'

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
      roles 
      username
      email
      defaultWallet
      wallets {
        address
        label
        type
      }
      created
    }
  }
`
