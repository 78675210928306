import { createMachine, interpret, assign } from 'xstate'
import {  useActor } from '@xstate/vue'

type loginModalAction = 'login' | 'signup'  | 'create_community' | 'add-wallet' | null

const loginModalMachine = createMachine({
  predictableActionArguments: true,
  id: 'connectModalMachine',
  schema: {
    context: {} as {
      action: loginModalAction
    },
    events: {} as
    | { type: 'SET_ACTION', data: loginModalAction }
    | { type: 'OPEN'}
    | { type: 'CLOSE'}
    | { type: 'CONNECT'},
  },
  context: {
    action: null,
  },
  initial: 'closed',
  on: {
    SET_ACTION: {
      actions: ['setAction'],
    },
  },
  states: {
    closed: {
      on: {
        OPEN: 'open',
      },
    },
    open: {
      on: {
        CONNECT: 'connecting',
        CLOSE: 'closed',
      },
    },
    connecting: {
      on: {
        CLOSE: 'closed',
      },
    },
  },
}, {
  actions: {
    setAction: assign({ action: (_, event) => (event as { data: loginModalAction }).data }),
  },
})

const service = interpret(loginModalMachine).start()

export const useLoginModal = () => {
  return useActor(service)
}
