<template>
  <div class="overflow-hidden rounded-xl pb-10">
    <div
      v-if="isMobile"
      class="flex flex-col items-center pt-5 bg-app"
    >
      <div class="space-y-3 px-4 text-center">
        <div class="rounded-lg font-semibold disabled:opacity-50 text-action-purple px-4 py-2 text-sm">
          <GlobalButton @click="handleWarpcastLoginClick">
            <FarcasterLogo class="w-10 h-10 md:w-12 md:h-12" />
            Login with Warpcast
          </GlobalButton>
        </div>
      </div>
      
      <div class="w-full py-6">
        <div class="relative">
          <div
            class="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div class="w-full border-t border-default" />
          </div>
          
          <div class="relative flex justify-center">
            <span class="px-2 text-sm text-gray-500 bg-app">or</span>
          </div>
        </div>
      </div>
      
      <a
        v-if="isAndroid"
        title="Download Android app"
        href="https://play.google.com/store/apps/details?id=com.farcaster.mobile"
        target="_blank"
      >
        <img
          src="https://warpcast.com/~/images/DownloadGoogle.png"
          alt="Download Android app"
          class="mb-4 max-w-[200px]"
        >
      </a>
      
      <a
        v-else
        class="text-center"
        title="Download iOS app"
        href="https://apps.apple.com/us/app/farcaster/id1600555445"
        target="_blank"
      >
        <img
          src="https://warpcast.com/~/images/DownloadApple.png"
          alt="Download iOS app"
          class="mb-4 inline max-w-[200px]"
        >
      </a>
    </div>
    
    <iframe
      v-if="!isMobile && farcasterUrl"
      ref="iframeRef"
      scrolling="no"
      class="w-[300px] md:w-[320px] h-[600px] md:h-[630px] -mt-14 md:-mt-24 -mb-10"
      :src="farcasterUrl"
    />
  </div>
</template>
  
<script setup lang="ts">
import { PropType } from 'vue'
import { isMobile, isAndroid } from '#utils'

import Body from '#components/typography/body'
import GlobalButton from '#components/global/global-button'
import FarcasterLogo from '#components/logos/farcaster-logo.vue'
  
const emit = defineEmits(['close'])
  
const props = defineProps({
  farcasterUrl: {
    type: String as PropType<string>,
    required: true,
    default: '',
  },
})

const handleWarpcastLoginClick = () => {
  window.open(props.farcasterUrl, '_blank')
}
  
</script>
  
