import type { DirectiveBinding } from 'vue'

type HandlerFunction = (event: Event) => void

interface CustomNode extends Node {
  out: HandlerFunction | null;
}

export default {
  beforeMount (element: CustomNode, binding: DirectiveBinding): void {
    const handler = (event: Event) => {
      if (!element.contains(event.target as Node) && element !== event.target) {
        binding.value()
      }
    }

    document.addEventListener('click', handler)
    element.out = handler
  },

  unmounted: (element: CustomNode): void => {
    document.removeEventListener('click', element.out as HandlerFunction)
    element.out = null
  },
}
