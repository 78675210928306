<template>
  <Modal
    :is-open="isOpen"
    :content-overflow="true"
    :hide-action-buttons="true"
    title="Connect X profile"
    subtitle="Connect your X profile to your remx account"
    :is-loading="false"
    :persistent-on-off="true"
    @close="handleClose"
  >
    <div class="w-full flex justify-center flex-col">
      <ConnectXProfile @connect-x-profile="handleConnectXProfile" />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useMutation } from '#composables/use-apollo'
import useSnackbar from '#composables/use-snackbar'

import { TWITTER_REQUEST_TOKEN } from '#mutations/TwitterRequestToken'
import type { twitterRequestToken, twitterRequestTokenVariables } from '#graphql/types/twitterRequestToken'

import Modal from '#components/modal'
import ConnectXProfile from './connect-x-profile.vue'

const emit = defineEmits(['close'])

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const { addSnack } = useSnackbar()

const handleConnectXProfile = async () => {
  twitterRequestTokenMutate({ input: { url: window.location.host } })
}

const handleClose = () => {
  emit('close')
}

/**
 * Mutations
**/

// TwitterRequestToken
const {
  mutate: twitterRequestTokenMutate,
  onResult: twitterRequestTokenOnResult,
  onError: twitterRequestTokenOnError,
} = useMutation<twitterRequestToken, twitterRequestTokenVariables>(TWITTER_REQUEST_TOKEN, { input: { url: window.location.host } })

twitterRequestTokenOnResult((result) => {
  if (result.data) {
    const { oauth_token } = result.data.twitterRequestToken

    window.open(`https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`, '_blank')

    handleClose()
  }
})

twitterRequestTokenOnError((error) => {
  console.log('error', error)

  addSnack({ message: 'Something went wrong trying to connect with X.  Please try again.', color: 'negative' })
})
</script>
