import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */
export const GET_COLLECTION = gql`
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      accountId,
      type
      icon
      banner
      description
      name
      contractName
      params
      size
      status
      soldCount
      totalRevenue
      contractId
      requestedChanges
      reservedCount
      archived
      chainId
      crossmintConfig {
        clientId
      }
      tokenGates {
        address
        appliesTo
        chainId
        name
      }
      premints {
        link
        appliesTo
      }
      auction {
        collectionId
        floorPrice
        airdropSize
        privatesaleSize
        comingSoonDate
        countdownDate
        privatesaleDate
        presaleDate
        saleDate
        endDate
        currency
        launchType
      }
      collaborators {
        account {
          id
          username
          icon
          type
          email
        }
        shares
      }
      tokens {
        cursor
        tokens {
          id
          collectionId
          accountId
          price
          status
        }
      }
      communityIds
      profiles {
        id
        username
        displayName
        accountId
        isFollowing
      }
      tokenLimit
      metadata
    }
  }
`
