<template>
  <button
    class="w-full flex gap-x-2 rounded-md"
    :disabled="$route.name === 'me-earnings'"
    @click="emit('click')"
  >
    <div 
      class="w-full flex gap-x-2 p-2 rounded-md border-transparent "
      :class="isActive ? '' : 'hover:bg-brand-bg-section cursor-pointer'"
    >
      <Icon
        :class="isActive? fillIconWhileActive ?'fill-current': 'stroke-[3px]' : 'fill-none stroke-2'"
        :icon="icon"
        class="h-6 w-6"
      />
  
      <span :class="isActive ? 'font-medium' : ''">{{ text }}</span>
    </div>
  </button>
</template>
    
<script setup lang="ts">
import { PropType } from 'vue'
  
import Icon from '#components/icon.vue'
  
defineProps({
  icon: {
    type: String as PropType<keyof typeof import('@creativelayer/chalk-icons')>,
    default: '',
  },
  
  text: {
    type: String,
    default: '',
  },
  
  fillIconWhileActive: {
    type: Boolean,
    default: false,
  },

  isActive: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])

</script>
  
