import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */
export const GET_SUPPORTED_NETWORKS = gql`
  query GetSupportedNetworks {
    getSupportedNetworks {
      defaultChainId
      chains {
        chainId
        alchemy {
          apiKey
          url
        }
        crossmintConfig {
          clientId
        }
      }
    }
  }
`
