<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 12.75C19.2875 12.75 19.1094 12.6777 18.9656 12.5332C18.8219 12.3886 18.75 12.2094 18.75 11.9957C18.75 11.7819 18.8219 11.6042 18.9656 11.4625C19.1094 11.3209 19.2875 11.25 19.5 11.25H21.75C21.9625 11.25 22.1406 11.3223 22.2844 11.4669C22.4281 11.6115 22.5 11.7907 22.5 12.0044C22.5 12.2182 22.4281 12.3959 22.2844 12.5375C22.1406 12.6792 21.9625 12.75 21.75 12.75H19.5ZM19.4 19.55L17.575 18.2C17.4083 18.0667 17.3083 17.9015 17.275 17.7044C17.2417 17.5073 17.2917 17.3225 17.425 17.15C17.5583 16.9834 17.7235 16.8834 17.9206 16.85C18.1177 16.8167 18.3025 16.8667 18.475 17L20.3 18.35C20.4667 18.4834 20.5667 18.6486 20.6 18.8457C20.6333 19.0428 20.5833 19.2276 20.45 19.4C20.3167 19.5667 20.1515 19.6667 19.9544 19.7C19.7573 19.7334 19.5725 19.6834 19.4 19.55ZM18.55 6.97504C18.3775 7.10837 18.1927 7.15837 17.9957 7.12504C17.7986 7.09171 17.6333 6.99171 17.5 6.82504C17.3667 6.65837 17.3167 6.47504 17.35 6.27504C17.3833 6.07504 17.4833 5.90837 17.65 5.77504L19.4 4.45004C19.5725 4.31671 19.7573 4.26671 19.9544 4.30004C20.1515 4.33337 20.3167 4.43337 20.45 4.60004C20.5833 4.76671 20.6333 4.95004 20.6 5.15004C20.5667 5.35004 20.4667 5.51671 20.3 5.65004L18.55 6.97504ZM5.75 19V15H4C3.5875 15 3.23438 14.8532 2.94063 14.5594C2.64687 14.2657 2.5 13.9125 2.5 13.5V10.5C2.5 10.0875 2.64687 9.73441 2.94063 9.44066C3.23438 9.14691 3.5875 9.00004 4 9.00004H8.5L12.375 6.67504C12.625 6.52504 12.875 6.52451 13.125 6.67344C13.375 6.82237 13.5 7.03957 13.5 7.32504V16.675C13.5 16.9605 13.375 17.1777 13.125 17.3266C12.875 17.4756 12.625 17.475 12.375 17.325L8.5 15H7.25V19H5.75ZM14.5 15.35V8.65004C14.95 9.05004 15.3125 9.53754 15.5875 10.1125C15.8625 10.6875 16 11.3167 16 12C16 12.6834 15.8625 13.3125 15.5875 13.8875C15.3125 14.4625 14.95 14.95 14.5 15.35ZM12 15.35V8.65004L8.9 10.5H4V13.5H8.9L12 15.35Z"
      fill="white"
    />
  </svg>
</template>
