<template>
  <div class="space-y-6">
    <Banner icon-type="bulb">
      Click 'Connect with X' to open up a new window and begin the authorization process on X.
    </Banner>

    <GlobalButton @click.prevent="handleConnectClick">
      Connect with <XIcon class="w-4 h-4 inline-block" />
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import GlobalButton from '#components/global/global-button'
import Banner from '#components/banner/banner.vue'
import XIcon from '#components/icons/x-platform-icon.vue'

defineProps({
  xProfileExists: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'connect-x-profile', 'post-to-x'])

const handleConnectClick = () => {
  emit('connect-x-profile')
}
</script>
