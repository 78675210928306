<template>
  <button
    v-if="!href"
    :class="classes.root"
    v-bind="$attrs"
    :disabled="(attrs.disabled as boolean)"
  >
    <div
      class="flex gap-x-2 items-center justify-center"
      :class="classes.insideButton"
    >
      <Icon
        v-if="icon"
        :icon="icon"
        :class="classes.icon"
      />

      <div
        v-if="$slots.icon"
        :class="classes.icon"
        class="flex items-center"
      >
        <slot
          name="icon"
          :icon-class="classes.icon"
        />
      </div>
      <slot />
    </div>

    <div 
      v-if="(attrs.disabled as boolean)"
      class="absolute top-[-2px] left-[-2px] w-[calc(100%+4px)] h-[calc(100%+4px)] bg-white opacity-50 rounded-full"
    />
  </button>

  <a
    v-if="href"
    :href="href"
    target="_blank"
    v-bind="$attrs"
    class="inline-block"
    :class="classes.root"
  >

    <div
      class="flex gap-x-2 items-center justify-center"
      :class="classes.insideButton"
    >
      <Icon
        v-if="icon"
        :icon="icon"
        :class="classes.icon"
      />

      <div
        v-if="$slots.icon"
        :class="classes.icon"
      >
        <slot
          name="icon"
          :icon-class="classes.icon"
        />
      </div>
      <slot />
    </div> 

    <div 
      v-if="(attrs.disabled as boolean)"
      class="absolute top-[-2px] left-[-2px] w-[calc(100%+4px)] h-[calc(100%+4px)] bg-white opacity-50 rounded-full"
    />
  </a>
</template>

<script setup lang="ts">
import { computed, useAttrs, type PropType } from 'vue'

import type { Type, Size } from '#types/button'

import Icon from '#components/icon.vue'

const attrs = useAttrs()

const props = defineProps({
  size: {
    type: String as PropType<Size>,
    default: 'base',
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },

  priority: {
    type: String as PropType<Type>,
    default: 'primary-light',
  },

  reverseIcon: {
    type: Boolean,
    default: false,
  },

  icon: {
    type: String as PropType<keyof typeof import('@creativelayer/chalk-icons')>,
    default: '',
  },

  href: {
    type: String,
    default: '',
  },

  isIconOnly: {
    type: Boolean,
    default: false,
  },
})

const buttonType = computed(() => {
  switch (props.priority) {
    default:
    case 'primary-light':
      return 'bg-black text-white border-black'
    case 'secondary-light':
      return 'bg-white text-black border-brand-border'
    case 'primary-dark':
      return 'bg-black text-white border-white'
    case 'secondary-dark':
      return 'bg-white text-black border-white'
    case 'primary-both':
      return 'bg-black text-white border-brand-copy-light'
    case 'transparent-light':
      return 'bg-transparent text-black border-transparent'
    case 'transparent-dark':
      return 'bg-transparent text-white border-transparent'
  }
})

const buttonSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return props.icon && props.isIconOnly ? 'p-2 text-xs lg:text-base' : 'py-2 px-4 lg:px-6 text-xs lg:text-base'
    case 'base':
    default:
      return props.icon && props.isIconOnly ? 'p-3 text-base lg:text-xl' : 'py-2 lg:py-4 px-4 lg:px-8 text-base lg:text-xl'
    case 'lg':
      return props.icon && props.isIconOnly ? 'p-3 text-xl lg:text-2xl' : 'py-4 lg:py-6 px-8 lg:px-14 text-xl lg:text-2xl'
  }
})

const iconSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'w-4 lg:w-5 h-4 lg:h-5 '
    case 'base':
    default:
      return 'w-5 lg:w-6 h-5 lg:h-6'
    case 'lg':
      return 'w-6 lg:w-7 h-6 lg:h-7'
  }
})

const focusClasses = (attrs.disabled as boolean) !== true && 'focus:outline outline-offset-[-2px] outline-2 focus:outline-black focus:hover:outline-none'

const activeClasses = (attrs.disabled as boolean) !== true && 'active:translate-y-0'

const hoverStates = (attrs.disabled as boolean) !== true && 'hover:-translate-y-[3px]'

const classes = computed(() => {
  return {
    root: [
      'relative rounded-full transition-transform duration-200 ease-out disabled:cursor-not-allowed border-2 font-medium whitespace-nowrap disabled:translate-y-[0]',
      hoverStates,
      buttonSize.value,
      focusClasses,
      activeClasses,
      buttonType.value,
      props.fullWidth ? 'w-full' : '',
    ],

    insideButton: [
      props.reverseIcon ? 'flex-row-reverse' : 'flex-row',
      props.fullWidth ? 'justify-center' : '',
    ],

    icon: [
      iconSize.value,
    ],
  }
})
</script>
