/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

export const initHeap = () => {
  const isProduction = import.meta.env.MODE === 'prod'

  window.heap = window.heap || [], window.heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; const r = document.createElement('script'); r.type = 'text/javascript', r.async = !0, r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js'; const a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(r, a); for (let n = function (e) { return function () { window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], o = 0; o < p.length; o++)window.heap[p[o]] = n(p[o]) }
  window.heap.load(isProduction ? '2264229761' : '2484852138')
}
