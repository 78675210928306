<template>
  <div class="w-full">
    <div class="md:pt-0">
      <div
        v-for="n in 2"
        :key="n" 
        class="group flex flex-row gap-2 md:gap-3 items-center text-left w-full pt-4"
      >
        <div class="animate-pulse aspect-square rounded-full bg-slate-100 w-8 h-8 md:w-12 md:h-12 overflow-hidden" />
        <div class="grow w-0">
          <div class="animate-pulse h-4 w-24 md:w-28 md:h-6 bg-slate-100" />
          <div class="animate-pulse h-3 w-16 md:w-20 md:h-4 bg-slate-100 mt-1 md:mt-2" />
        </div>
      </div>
    </div>
  </div>
</template>
