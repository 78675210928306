<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <g clip-path="url(#clip0_1_3494)">
      <circle
        cx="24"
        cy="24"
        r="24"
        fill="#855DCD"
      />
      <path
        d="M16.5 15H31.5V33H29.2982V24.7548H29.2766C29.0332 22.4761 26.7637 20.6903 24 20.6903C21.2363 20.6903 18.9668 22.4761 18.7234 24.7548H18.7018V33H16.5V15Z"
        fill="white"
      />
      <path
        d="M13.5 16.5L14.3553 19.2293H15.0789V30.2707C14.7156 30.2707 14.4211 30.5484 14.4211 30.891V31.6354H14.2895C13.9261 31.6354 13.6316 31.9131 13.6316 32.2557V33H21V32.2557C21 31.9131 20.7055 31.6354 20.3421 31.6354H20.2105V30.891C20.2105 30.5484 19.916 30.2707 19.5526 30.2707H18.7632V16.5H13.5Z"
        fill="white"
      />
      <path
        d="M28.4474 30.2707C28.084 30.2707 27.7895 30.5484 27.7895 30.891V31.6354H27.6579C27.2945 31.6354 27 31.9131 27 32.2557V33H34.3684V32.2557C34.3684 31.9131 34.0739 31.6354 33.7105 31.6354H33.5789V30.891C33.5789 30.5484 33.2844 30.2707 32.9211 30.2707V19.2293H33.6447L34.5 16.5H29.2368V30.2707H28.4474Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3494">
        <rect
          width="48"
          height="48"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
