import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

import RootRoutes from '#pages/root/routes'
import ProfileRoutes from '#pages/profile/routes'
import FAQRoutes from '#pages/faq/routes'
import ContactRoutes from '#pages/contact/routes'
import CommunityRoutes from '#pages/community/routes'
import FeedRoutes from '#pages/feed/routes'
import OnboardingRoutes from '#pages/onboarding/routes'

import useLogin from '#composables/use-login'
const { isAuthReady, isAuthenticated } = useLogin()

const history = createWebHistory()

const routes: any = [
  ...RootRoutes,
  ...ProfileRoutes,
  FAQRoutes,
  ContactRoutes,
  ...CommunityRoutes,
  FeedRoutes,
  ...OnboardingRoutes,
]

export const router = createRouter({
  history,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      // Scroll with a timeout because transitions between views is 300ms and in
      // Safari this will trigger a warning that the element is not yet
      // available.
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: 80,
            behavior: 'smooth',
          })
        }, 500)
      })
    }

    // if the returned position is falsy or an empty object, will retain current
    // scroll position. (borrowed from https://stackoverflow.com/a/62208231)
    // Note: this works, but then disables automatic isActive on correct nav link on
    // intital render so need to revisit this if used on TabNavs
    if (to.meta.noScroll) {
      return {}
    } else if (to.name === from.name) {
      // if you click on a button for the same page, it will smooth scroll you to
      // the top, but if you go to a different route, it will use a transition
      return savedPosition || { top: 0, behavior: 'smooth' }
    } else {
      // setTimeout time set a little higher than transition time to help
      // alevate some of the jankiness of it jumping to the top of the page
      // during the transition.
      return savedPosition || new Promise(resolve => setTimeout(() => resolve({ top: 0 }), 320))
    }
  },
})

router.beforeEach(async (to) => {
  if (to.meta.requiresAuthentication) {
    await isAuthReady()
    if (!isAuthenticated.value) {
      return '/'
    }
  }
})

router.afterEach((to) => {
  const title = to.meta.title ? `remx - ${to.meta.title}` : 'remx'
  document.title = title
})

trackRouter(router)

export default router
