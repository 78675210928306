const Onboarding = () => import('#pages/onboarding/onboarding.vue')
const CreateAccount = () => import('#pages/onboarding/create-account.vue')
const CreateProfile = () => import('#pages/onboarding/create-profile.vue')
const AddProfileAvatar = () => import('#pages/onboarding/add-profile-avatar.vue')
const AddEmail = () => import('#pages/onboarding/add-email.vue')
const FollowAccounts = () => import('#pages/onboarding/follow-accounts.vue')


export default [
  {
    path: '/start',
    name: 'start-index',
    redirect: () => ({ name: 'start-create-account' }),
    component: Onboarding,
    meta: {
      title: 'remx - Create your account',
      hideGlobalHeader: true,
    },
    children: [
      {
        path: 'create-account',
        name: 'start-create-account',
        component: CreateAccount,
        meta: {
          title: 'remx - Create your account',
        },
      },
      {
        path: 'name',
        name: 'start-name',
        component: CreateProfile,
        meta: {
          title: 'remx - Create a profile name',
        },
      },
      {
        path: 'avatar',
        name: 'start-avatar',
        component: AddProfileAvatar,
        meta: {
          title: 'remx - Add a profile avatar',
        },
      },
      {
        path: 'email',
        name: 'start-email',
        component: AddEmail,
        meta: {
          title: 'remx - Add an email',
        },
      },
      {
        path: 'follow-accounts',
        name: 'start-follow-accounts',
        component: FollowAccounts,
        meta: {
          title: 'remx - Who to follow',
        },
      },
    ],
  },
]
