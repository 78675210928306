import { computed } from 'vue'

export const stripTypeNameFromObject = (obj: object) => {
  const omitTypename = (key, value) => (key === '__typename' ? undefined : value)

  return JSON.parse(JSON.stringify(obj), omitTypename)
}

export const isMobile = computed(() => (/iPad|iPhone|iPod|android/i.test(navigator.userAgent)) ? true : false)

export const isAndroid = computed(() => (/android/i.test(navigator.userAgent)) ? true : false)
