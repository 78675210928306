import { useLazyQuery, useMutation } from '#composables/use-apollo'
import { requestUploadQuery, uploadImageSigned } from './uploadUtils'
import { v4 as uuidv4 } from 'uuid'

import type { Ref } from 'vue'
import type { CollectionParams } from '#types/collection'
import { Model } from '#types/collection'

import { CollectionTypes } from '#graphql/types/globalTypes'
import { GET_COLLECTION } from '#queries/GetCollection'
import type { GetCollection, GetCollectionVariables } from '#graphql/types/GetCollection'
import { CREATE_COLLECTION } from '#mutations/CreateCollection'
import type { CreateCollection, CreateCollectionVariables } from '#graphql/types/CreateCollection'
import { UPDATE_COLLECTION } from '#mutations/UpdateCollection'
import type { UpdateCollection, UpdateCollectionVariables } from '#graphql/types/UpdateCollection'

const {
  load: createCollection,
} = useMutation<CreateCollection, CreateCollectionVariables>(CREATE_COLLECTION)

const {
  load: updateCollection,
} = useMutation<UpdateCollection, UpdateCollectionVariables>(UPDATE_COLLECTION)

/**
 * saves a collection to the DB or updates it if an collectionId is provided
 * updloads all textures to S3 e replaces them in collection params
 */
export const saveCollection = async (collectionParams: CollectionParams, texturePalette: string[], rareTexture: string, account: string, collectionName: string, snapshot: string, id = ''): Promise<string> => {
  const params = { ...collectionParams }
  let collectionId = ''

  // first create the collection to get the ID,
  // then update it with the texturePallete and Icon (so we get a folder name)
  try {
    if (!id) {
     
      const { data } = await createCollection({
        input: {
          accountId: account,
          type: CollectionTypes.REMX,
          name: collectionName,
          contractName: collectionName,
          size: params.length,
        },
      })

      if (data?.createCollection?.id) {
        collectionId = data.createCollection?.id
      }
      
    } else {
      collectionId = id
    }
  } catch (error) {
    return Promise.reject('there was an error creating the collection')
  }

  // upload texturePalette
  if (texturePalette.length) {
    try {
      const textures = await Promise.all(
        texturePalette.map(async (texture) => {
          if (texture.includes('blob')) {
            // only upload textures in memory
            try {
              // get file from ObjectURL
              const textureResponse = await fetch(texture)
              const blob = await textureResponse.blob()
              const fileName = `${uuidv4()}.jpg`
              const file = new File([blob], fileName, { type: blob.type })
              // get signed url for every texture
              const { url: signedURL, fields } = await requestUploadQuery(account, `collection/${collectionId}/${fileName}`)
              // upload to S3
              await uploadImageSigned(file, fields, signedURL)
              // return keys
              return `${account}/collection/${collectionId}/${fileName}`
            } catch (error) {
              return Promise.reject(`there was an error uploading texture ${texture}`)
            }
          } else {
            return texture
          }
        })
      )
      // replace texture blobs with with image server keys
      params.texturePalette = textures
    } catch (error) {
      return Promise.reject('there was an error uploading the texture palette')
    }
  }

  // upload rare texture if one is provided, only if it's in memory
  if (rareTexture && rareTexture.includes('blob')) {
    try {
      const textureResponse = await fetch(rareTexture)
      const blob = await textureResponse.blob()
      const fileName = `${uuidv4()}.jpg`
      const file = new File([blob], fileName, { type: blob.type })
      const { url: signedURL, fields } = await requestUploadQuery(account, `collection/${collectionId}/${fileName}`)
      await uploadImageSigned(file, fields, signedURL)
      params.fixedRareTexture = `${account}/collection/${collectionId}/${fileName}`
    } catch (error) {
      return Promise.reject('there was an error uploading the rare texture')
    }
  }

  // upload icon
  let iconURL
  try {
    const textureResponse = await fetch(snapshot)
    const blob = await textureResponse.blob()
    const file = new File([blob], 'icon.jpg', { type: blob.type })
    const { url: signedURL, fields } = await requestUploadQuery(account, `collection/${collectionId}/icon.jpg`)
    await uploadImageSigned(file, fields, signedURL)
    iconURL = `${account}/collection/${collectionId}/icon.jpg`
  } catch (error) {
    return Promise.reject('there was an error uploading the collection icon')
  }

  await updateCollection({
    input: {
      id: collectionId,
      name: collectionName,
      contractName: collectionName,
      params: JSON.stringify(params),
      size: params.length,
      icon: iconURL,
    },
  })

  return collectionId as string
}

const {
  load: getCollection,
} = useLazyQuery<GetCollection, GetCollectionVariables>(GET_COLLECTION, { id: '' }, { fetchPolicy: 'no-cache' })

/**
 * fetches a collection from the database
 * and sets builder refs to the values that are returned from it
 */
export const fetchCollection = async (
  collectionId: string,
  // collectionParams
  model: Ref<string>,
  backgroundColor: Ref<string>,
  colorPalette: Ref<string[]>,
  colorRarity: Ref<number>,
  fixedRareColor: Ref<string>,
  hasFixedRareColor: Ref<boolean>,
  hasFixedRareTexture: Ref<boolean>,
  modelElements: Ref<{ name: string, selected: boolean }[]>,
  randomizeBackground: Ref<boolean>,
  seed: Ref<number>,
  texturePalette: Ref<string[]>,
  textureRarity: Ref<number>,
  fixedRareTexture: Ref<string>,
  // additional refs
  saveCollectionInput: Ref<{ name: string }>,
  collectionStatus: Ref<string>,
  collectionOwner: Ref<string>,
  collectionRequest: Ref<string>
) => {
  const { data } = await getCollection({ id: collectionId as string }, { fetchPolicy: 'network-only' })
  if (data?.getCollection) {
    const params = JSON.parse(data.getCollection.params as string)
    model.value = params.model
    backgroundColor.value = params.backgroundColor
    colorPalette.value = params.colorPalette
    colorRarity.value = params.colorRarity * 100
    fixedRareColor.value = params.fixedRareColor
    hasFixedRareColor.value = params.hasFixedRareColor
    hasFixedRareTexture.value = params.hasFixedRareTexture
    modelElements.value = params.modelElements
    randomizeBackground.value = params.randomizeBackground
    seed.value = params.seed
    texturePalette.value = params.texturePalette
    textureRarity.value = params.textureRarity * 100
    fixedRareTexture.value = params.fixedRareTexture
    saveCollectionInput.value = {
      name: data.getCollection.name || '',
    }
    collectionStatus.value = data.getCollection.status
    collectionOwner.value = data.getCollection?.accountId || ''
    collectionRequest.value = data.getCollection?.requestedChanges || ''
  }
}

export const getTextureContraints = (model: Model | null) => {
  switch (model) {
    case Model.PUFFER_JACKET:
    case Model.HOODIE:
    case Model.ROLLIE_BOLT:
    case Model.MOTORCYCLE_HELMET:
    case Model.PSYCHROME_POOP:
    case Model.SNEAKHEADS:
      return {
        maxFileSize: 600,
        maxDimension: 2048,
        aspectRatio: 1,
      }
    default:
      return {
        maxFileSize: 300,
        maxDimension: 1024,
        aspectRatio: 1,
      }
  }
}
