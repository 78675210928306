const Feed = () => import('#pages/feed/feed.vue')

export default {
  path: '/feed',
  name: 'feed',
  component: Feed,
  meta: {
    title: 'Limited edition digi-physical drops you won’t find anywhere else.',
  },
}
