import { gql } from '@apollo/client/core'

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: AccountInput!) {
    updateAccount(input: $input) {
      id
      email
      username
      bio
      type
      icon
      banner
      verified
      wallet
      defaultWallet
      wallets {
        address
        label
        type
      }
      roles
      notifications {
        enabled
        emailMigrationMessage
        settingsMigrationMessage
        loginMigrationMessage
      }
      social {
        twitter
        instagram
        website
      }
      showroomId
      showroom {
        id
      }
      profile { 
        accountId
        id
        displayName
        username
        icon
        banner
        website
        bio
        followerCount
        followingCount
        collectorCount
        isFollowing
        dropCount
        verified
      }
    }
  }
`
