import { ref } from 'vue'

const wallet = ref()

const updateWalletInfo = (data: any) => {
  wallet.value = data
}

const resetWalletInfo = () => {
  wallet.value = ''
}

export function useAddWallet () {
  return {
    wallet,

    updateWalletInfo,
    resetWalletInfo,
  }
}
