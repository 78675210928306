import { isRef } from 'vue'

/**
 * Recursively loop through an object and remove refs.
 */
export const unRefObject = (original: Record<string, any>, result: Record<string, any>) => {
  Object.keys(original).forEach(key => {
    if (isRef(original[key])) {
      result[key] = original[key].value
      return
    } else {
      result[key] = original[key]
    }

    if (typeof original[key] === 'object' && !Array.isArray(original[key]) && original[key] !== null) {
      result[key] = {}
      unRefObject(original[key], result[key])
    }
  })
}

/**
 * Registers callback functions to be triggered with supplied parameters.
 */
export type UseEventHookFunctions<T> = Array<(param: T) => void>
export type UseEventHookFunction<T> = (param: T) => void

export const useEventHook = <T>() => {
  const fns: UseEventHookFunctions<T> = []
  const on = (fn: UseEventHookFunction<T>) => {
    fns.push(fn)
  }

  const trigger = (param: T) => {
    for (const fn of fns) {
      fn(param)
    }
  }

  return {
    on,
    trigger,
  }
}
