import type { GetConnectorsReturnType } from '@wagmi/core'

export const prioritizeConnectors = (connectors: GetConnectorsReturnType) => {
  // copy the connectors array so we aren't mutating that directly
  const arr = [...connectors]

  let metamaskIndex = -1
  let coinbaseIndex = -1
  let walletConnectIndex = -1

  // Find the index of 'metamask' in the array
  const metamaskObject = arr.find((connector, index: number) => {
    if (connector.name === 'MetaMask') {
      metamaskIndex = index
      return connector
    }
  })

  // Find the index of 'coinbase' in the array
  const coinbaseObject = arr.find((connector, index: number) => {
    if (connector.name === 'Coinbase Wallet') {
      coinbaseIndex = index
      return connector
    }
  })

  // Find the index of 'walletconnect' in the array
  const walletConnectObject = arr.find((connector, index: number) => {
    if (connector.name === 'WalletConnect') {
      walletConnectIndex = index
      return connector
    }
  })

  if ((metamaskObject && metamaskIndex >= 0) && (coinbaseObject && coinbaseIndex >= 0) && (walletConnectObject && walletConnectIndex >= 0)) {
    // remove the connectors from the array
    arr.splice(metamaskIndex, 1)
    arr.splice(coinbaseIndex, 1)
    arr.splice(walletConnectIndex, 1)

    // add connectors back in the array in correct order
    arr.splice(0, 0, metamaskObject, coinbaseObject, walletConnectObject)
  } else if ((metamaskObject && metamaskIndex >= 0) && (coinbaseObject && coinbaseIndex >= 0)) {
    // remove the connectors from the array
    arr.splice(metamaskIndex, 1)
    arr.splice(coinbaseIndex, 1)

    // add connectors back in the array in correct order
    arr.splice(0, 0, metamaskObject, coinbaseObject)
  } else if ((metamaskObject && metamaskIndex >= 0) && (walletConnectObject && walletConnectIndex >= 0)) {
    // remove the connectors from the array
    arr.splice(metamaskIndex, 1)
    arr.splice(walletConnectIndex, 1)

    // add connectors back in the array in correct order
    arr.splice(0, 0, metamaskObject, walletConnectObject)
  } else if ((coinbaseObject && coinbaseIndex >= 0) && (walletConnectObject && walletConnectIndex >= 0)) {
    // remove the connectors from the array
    arr.splice(coinbaseIndex, 1)
    arr.splice(walletConnectIndex, 1)

    // add connectors back in the array in correct order
    arr.splice(0, 0, coinbaseObject, walletConnectObject)
  }

  // remove crossmint connector from the array as we handle it
  // separately at component level
  return arr.filter((connector) => connector.type !== 'crossmint')
}
