const FAQ = () => import('#pages/faq/faq.vue')

export default {
  path: '/faq',
  name: 'faq',
  component: FAQ,
  meta: {
    title: 'FAQ',
  },
}
