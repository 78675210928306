<template>
  <div
    class="relative bg-black px-4 py-4 md:py-2 text-center md:text-left text-white font-medium text-xs rounded-md md:rounded-b-none"
  >
    <Body
      size="xs"
      white-text
      class="font-medium uppercase"
    >
      Network
    </Body>

    <Body
      size="sm"
      white-text
      data-test-id="connected-chain-name"
    >
      {{ getChainInfo(connectedChainId).name }}
    </Body>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { getChainInfo } from '#utils'
import Body from '#components/typography/body'

const props = defineProps({
  connectedChainId: {
    type: Number,
    required: true,
    validator: (value: number) => [1, 3, 4, 5, 42, 137, 80001, 11155111].includes(value),
  },
})

const { connectedChainId } = toRefs(props)
</script>
