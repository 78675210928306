import { ref } from 'vue'

const isOpen = ref(false)

export default function useConnectX () {
  const openConnectXModal = () => {
    isOpen.value = true
  }

  const closeConnectXModal = () => isOpen.value = false

  return {
    isOpen,
    openConnectXModal,
    closeConnectXModal,
  }
}
