import { createConnector } from '@wagmi/core'
import { type Address, type ProviderMessage, zeroAddress, hexToString } from 'viem'
import { mainnet, polygon, sepolia, polygonAmoy, base, baseSepolia } from 'viem/chains'
import { CrossmintEVMWalletAdapter, CrossmintEnvironment, BlockchainTypes } from '@crossmint/connect'

export type CrossmintParameters = {
  apiKey: string
  environment?: CrossmintEnvironment
  chain: BlockchainTypes.ETHEREUM | BlockchainTypes.POLYGON
  maxTimeAutoConnect?: number
}

interface ExtendedCrossmintEVMWalletAdapter extends CrossmintEVMWalletAdapter {
  request?: (args: { method: string; params: string[] }) => Promise<string | undefined>;
}

export function crossMint (parameters: CrossmintParameters = { apiKey: '', chain: BlockchainTypes.ETHEREUM }) {
  return createConnector((config) => {
    // config is the connector configuration
    console.log(config)
    let account: Address | undefined = undefined

    // change the type of crossmintProvider to CrossmintEVMWalletAdapter and a function called request that returns a promise
    let crossmintProvider: ExtendedCrossmintEVMWalletAdapter | undefined = undefined

    async function getProvider () {
      console.log(`getProvider`, parameters )
      if (!crossmintProvider) {
        crossmintProvider = new CrossmintEVMWalletAdapter(parameters)
        crossmintProvider.request = async ({ method, params }) => {
          if (method == 'personal_sign') {
            return await crossmintProvider?.signMessage(hexToString(params[0] as `0x${string}`)) as string
          }
        }
      }
      return crossmintProvider

    }
    return {
      // icon: '',
      id: 'crossMint',
      name: 'CrossMint',
      type: 'crossmint',
      async connect (parameters: { chainId?: number, isReconnecting?: boolean }) {
        console.log('connect')
        const provider = await getProvider()
        account = await provider.connect() as Address
        return {
          accounts: [ account],
          chainId: await this.getChainId(),
        }
    
      },
      async disconnect () {
        console.log('disconnect')
        const provider = await getProvider()
        provider.disconnect()
      },
      async getAccounts () {
        return [account || zeroAddress]
      },
      async getChainId () {
        if (import.meta.env.VITE_CROSSMINT_ENVIRONMENT == 'PRODUCTION') {
          return parameters.chain == BlockchainTypes.ETHEREUM ? 1 : 137
        } else {
          return parameters.chain == BlockchainTypes.ETHEREUM ? 11155111 : 80002
        }
      },
      async getProvider () {
        return getProvider()
      },
      async isAuthorized () {
        return false
      },
      async setup () {
        console.log('setup')
      },
      async switchChain (parameters: {chainId: number}) {
        console.log('switchChain', parameters.chainId)
        switch (parameters.chainId) {
          case 137:
            return polygon
            break
          case 11155111:
            return sepolia
            break
          case 80002:
            return polygonAmoy
            break
          case 84532:
            return baseSepolia
            break
          case 8453:
            return base
            break
          case 1:
          default:
            return mainnet
            break
        }
      },
      onAccountsChanged (accounts: Address[]) {
        console.log('onAccountsChanged', accounts)
      },
      onChainChanged (chainId: string) {
        console.log('onChainChanged', chainId)
      },
      onDisconnect (error: Error) {
        console.log('onDisconnect', error)
      },
      onConnect () {
        console.log('onConnect')
      },
      onMessage (message: ProviderMessage) {
        console.log('onMessage', message)
      },
    }
  })

}



/*


import { Address, Connector, ConnectorData, WalletClient } from '@wagmi/core'
import { Chain, mainnet, polygon } from '@wagmi/chains'
import { createWalletClient, custom } from 'viem'

import { CrossmintEVMWalletAdapter, CrossmintEnvironment, BlockchainTypes } from '@crossmint/connect'

type CrossmintOptions = {
  apiKey: string
  environment?: CrossmintEnvironment
  chain: BlockchainTypes.ETHEREUM | BlockchainTypes.POLYGON
  // autoConnect?: boolean
  maxTimeAutoConnect?: number
}

export class CrossmintConnector extends Connector<CrossmintEVMWalletAdapter, CrossmintOptions> {
  readonly id = 'crossMint'
  readonly name = 'CrossMint'
  readonly ready = true

  #options: CrossmintOptions
  provider?: CrossmintEVMWalletAdapter

  #account?: Address

  constructor (config: { chains?: Chain[], options: CrossmintOptions }) {
    super(config)
    this.#options = Object.assign({
      environment: CrossmintEnvironment.STAGING,
      chain: BlockchainTypes.POLYGON,
      autoConnect: false,
      maxTimeAutoConnect: 1000,
      appMetadata: {
        icon: 'https://crossmint.com/favicon.ico',
      },
    }, config.options)
  }

  async connect (): Promise<Required<ConnectorData>> {
    this.emit('message', { type: 'connecting' })
    const provider = await this.getProvider()
    this.#account = await provider.connect() as Address
    return {
      account: this.#account,
      chain: {
        id: await this.getChainId(),
        unsupported: false,
      },
    }
  }

  async disconnect () {
    console.log('disconnect')
    const provider = await this.getProvider()
    provider.disconnect()
  }

  async getAccount (): Promise<Address> {
    return this.#account || '0x0'
  }

  async getChainId (): Promise<number> {
    if (import.meta.env.VITE_CROSSMINT_ENVIRONMENT == 'PRODUCTION') {
      return this.#options.chain == BlockchainTypes.ETHEREUM ? 1 : 137
    } else {
      return this.#options.chain == BlockchainTypes.ETHEREUM ? 11155111 : 80001
    }
  }

  async getProvider () {
    if (!this.provider) {
      this.provider = new CrossmintEVMWalletAdapter(this.#options)
    }
    return this.provider
  }

  onConnect () {
    let id
    if (import.meta.env.VITE_CROSSMINT_ENVIRONMENT == 'PRODUCTION') {
      id = this.#options.chain == BlockchainTypes.ETHEREUM ? 1 : 137
    } else {
      id = this.#options.chain == BlockchainTypes.ETHEREUM ? 11155111 : 80001
    }

    this.emit('connect', {
      account: this.#account,
      chain: {
        id,
        unsupported: false,
      },
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onAccountsChanged (accounts: `0x${string}`[]): void { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onChainChanged (chain: number | string): void { }

  protected onDisconnect (error: Error): void {
    console.log('CrossMint Connector: onDisconnect', error)
    this.emit('disconnect')
  }

  async getWalletClient (): Promise<WalletClient> {
    const provider = await this.getProvider()
    return createWalletClient(
      {
        account: await this.getAccount(),
        chain: this.#options.chain == BlockchainTypes.ETHEREUM ? mainnet : polygon,
        transport: custom({
          async request ({ method, params }) {
            console.log('CrossMint Connector: request', method, params)
          },
        }),
      }
    ).extend(() => ({
      signMessage: async ({ message }) => {
        return provider.signMessage(message)
      },
    }))
  }

  async isAuthorized (): Promise<boolean> {
    return false
  }

  async signMessage (message: string) {
    const provider = await this.getProvider()
    return provider.signMessage(message)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async signTypedData (typedData: any) {
    console.log('CrossmintConnector signTypedData not supported', typedData)
  }
}
*/
