import { getChainInfo } from '#utils'

import { useExchangePrices } from '#composables/use-exchange-prices'

const { ethPrice, maticPrice } = useExchangePrices()

export const convertCurrencyToCrypto = (blockchain: number | null, usd: number | null) => {

  const blockchainPrices = {
    maticPrice: maticPrice,
    ethPrice: ethPrice,
  }

  const usdPrice = Number(usd)
  const blockchainCurrency = getChainInfo(blockchain).currency
  const decimals = getChainInfo(blockchain).decimals
  const convertedCurrencyVariable = getChainInfo(blockchain).currencyConversion || 'maticPrice'
  const convertedCurrency = blockchainPrices[convertedCurrencyVariable]

  return `${Number(usdPrice/convertedCurrency.value).toFixed(decimals)} ${blockchainCurrency}`
}
