import { gql } from '@apollo/client/core'

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      id
      name
      size
      metadata
    }
  }
`
