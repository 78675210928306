<template>
  <component
    :is="iconComponent"
  />
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'
import * as icons from '@creativelayer/chalk-icons'

const props = defineProps({
  icon: {
    type: String as PropType<keyof typeof import('@creativelayer/chalk-icons')>,
    default: '',
  },
})

const iconComponent = computed (() => {
  return icons[props.icon]
})
</script>
