import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */
export const REQUEST_UPLOAD = gql`
  query RequestUpload($file: String!, $id: String!, $mimeType: String!) {
    requestUpload(file: $file, id: $id, mimeType: $mimeType) {
      url,
      fields {
        key,
        bucket,
        Policy,
        X_Amz_Algorithm,
        X_Amz_Credential,
        X_Amz_Date,
        X_Amz_Security_Token,
        X_Amz_Signature,
      }
    }
  }
`
