import { gql } from '@apollo/client/core'

/**
 * Gets a user's account.
 */

export const REQUEST_UPLOAD_FOR_SHARE = gql`
  query RequestUploadForShare($file: String!, $title: String!, $description: String!, $type: String!) {
    requestUploadForShare(file: $file, title: $title, description: $description, type: $type) {
      url,
      htmlUrl,
      fields {
        key,
        bucket,
        Policy,
        X_Amz_Algorithm,
        X_Amz_Credential,
        X_Amz_Date,
        X_Amz_Security_Token,
        X_Amz_Signature,
      }
    }
  }
`
