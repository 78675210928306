<template>
  <transition
    enter-active-class="transition transform duration-300 ease-out"
    enter-from-class="opacity-0 translate-y-1/2"
    enter-to-class="opacity-100"
    leave-active-class="transition transform duration-300 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 translate-y-1/2"
  >
    <div
      v-if="needRefresh"
      class="fixed z-40 bottom-0 p-4 w-full  md:max-w-lg md:right-0"
      role="alert"
      data-test-id="reload-prompt"
    >
      <div class="bg-white p-12 rounded-xl shadow-lg space-y-4 outline outline-1 outline-brand-border">
        <div class="message">
          <span class="font-medium text-2xl md:text-3xl">There are new updates for this site</span><br>
          <span class="text-gray-600 md:text-xl">Please reload this site to update to the latest version.</span>
        </div>
        <div class="space-x-2 text-right">
          <GlobalButton
            priority="secondary-light"
            data-test-id="close"
            @click="close"
          >
            Close
          </GlobalButton>
          <GlobalButton
            v-if="needRefresh"
            priority="primary-light"
            data-test-id="reload"
            @click="updateServiceWorker()"
          >
            Reload
          </GlobalButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import GlobalButton from '#components/global/global-button'

const {
  needRefresh,
  updateServiceWorker,
} = useRegisterSW({
  immediate: true,
  onRegistered (r) {
    r && setInterval(async () => {
      await r.update()
    }, 10000)
  },
})

const close = async () => {
  needRefresh.value = false
}

defineExpose({
  needRefresh,
})
</script>
