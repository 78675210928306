import { getHours } from 'date-fns'

import { ref } from 'vue'

import { GET_EXCHANGE_PRICES } from '#queries/GetExchangePrices'
import type { GetExchangePrices } from '#graphql/types/GetExchangePrices'

import { useLazyQuery } from '#composables/use-apollo'

const {
  load: getExchangePrices,
} = useLazyQuery<GetExchangePrices, never>(GET_EXCHANGE_PRICES)

const TEN_MINUTES = 10 * 60000
const ONE_HOUR = 60 * 60000

export function useExchangePrices () {
  const maticPrice = ref(0)
  const ethPrice = ref(0)

  const update = async () => {
    try {
      const { data } = await getExchangePrices()
      if (data?.getExchangePrices) {
        const { eth, matic } = data.getExchangePrices
        ethPrice.value = Number(eth)
        maticPrice.value = Number(matic)
      }
    } catch (error) {
      console.error(error)
    }
  }

  update()

  // update the exchange rate every 10 mins between 9am-5pm otherwise update it every hour.
  const hours = getHours(new Date)
  if (hours >= 9 && hours <= 17) {
    setInterval(update, TEN_MINUTES)
  } else {
    setInterval(update, ONE_HOUR)
  }

  return {
    ethPrice,
    maticPrice,
  }
}
