import { gql } from '@apollo/client/core'

export const ON_ACCOUNT_UPDATED = gql`
  subscription OnAccountUpdated($id: ID!) {
    accountUpdated(id: $id) {
      id
      withdrawalStatus{
        status
        message
      }
    }
  }
`
