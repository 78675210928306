<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
      fill="url(#paint0_radial_119_5488)"
    />
    <path
      d="M15.8634 17.2998C20.3571 12.9001 27.6429 12.9001 32.1366 17.2998L32.6774 17.8293C32.9021 18.0493 32.9021 18.406 32.6774 18.6259L30.8274 20.4373C30.715 20.5473 30.5329 20.5473 30.4206 20.4373L29.6763 19.7086C26.5414 16.6393 21.4587 16.6393 18.3237 19.7086L17.5267 20.489C17.4144 20.599 17.2322 20.599 17.1199 20.489L15.2698 18.6776C15.0451 18.4576 15.0451 18.101 15.2698 17.881L15.8634 17.2998ZM35.9627 21.0459L37.6093 22.658C37.8339 22.878 37.834 23.2346 37.6093 23.4546L30.1848 30.7239C29.9601 30.9439 29.5959 30.9439 29.3712 30.7239C29.3712 30.7239 29.3712 30.7239 29.3712 30.7239L24.1018 25.5647C24.0456 25.5097 23.9545 25.5097 23.8983 25.5647C23.8983 25.5647 23.8983 25.5647 23.8983 25.5647L18.629 30.7239C18.4044 30.9439 18.0401 30.9439 17.8154 30.7239C17.8154 30.7239 17.8154 30.7239 17.8154 30.7239L10.3907 23.4545C10.1661 23.2346 10.1661 22.8779 10.3907 22.6579L12.0373 21.0458C12.262 20.8258 12.6263 20.8258 12.851 21.0458L18.1204 26.2051C18.1766 26.26 18.2677 26.26 18.3239 26.2051C18.3239 26.205 18.3239 26.205 18.3239 26.205L23.5931 21.0458C23.8178 20.8258 24.1821 20.8258 24.4068 21.0458C24.4068 21.0458 24.4068 21.0458 24.4068 21.0458L29.6762 26.205C29.7324 26.26 29.8235 26.26 29.8797 26.205L35.149 21.0459C35.3737 20.8259 35.738 20.8259 35.9627 21.0459Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_119_5488"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 24) scale(48)"
      >
        <stop stop-color="#5D9DF6" />
        <stop
          offset="1"
          stop-color="#006FFF"
        />
      </radialGradient>
    </defs>
  </svg>
</template>
