<template>
  <button
    class="rounded-md w-full flex items-center justify-center"
    @click="emit('click')"
  >
    <div 
      class="rounded-md border-transparent"
    >
      <Icon
        v-if="icon"
        class="text-white h-7 w-7"
        :class="isActive? fillIconWhileActive ?'fill-current': 'stroke-[3px]' : 'fill-none stroke-2'"
     
        :icon="icon"
      />
      <slot />
    </div>
  </button>
</template>
    
<script setup lang="ts">
import { PropType } from 'vue'
  
import Icon from '#components/icon.vue'
  
defineProps({
  icon: {
    type: String as PropType<keyof typeof import('@creativelayer/chalk-icons')>,
    default: null,
  },
    
  isActive: {
    type: Boolean,
    default: false,
  },

  fillIconWhileActive: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])

</script>
  
