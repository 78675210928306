<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_21_1768)">
      <path
        d="M45.6093 0L26.8799 13.9106L30.3434 5.70353L45.6093 0Z"
        fill="#E2761B"
        stroke="#E2761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.37164 0L20.9505 14.0424L17.6564 5.70353L2.37164 0Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.8705 32.2456L33.8822 39.888L44.5552 42.8244L47.6234 32.415L38.8705 32.2456Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.395203 32.415L3.44461 42.8244L14.1176 39.888L9.12932 32.2456L0.395203 32.415Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5151 19.3326L10.541 23.8315L21.1387 24.3021L20.7622 12.9138L13.5151 19.3326Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.4658 19.3326L27.1246 12.782L26.8799 24.302L37.4587 23.8314L34.4658 19.3326Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1175 39.8871L20.4799 36.7813L14.9834 32.4895L14.1175 39.8871Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.5011 36.7813L33.8823 39.8871L32.9976 32.4895L27.5011 36.7813Z"
        fill="#E4761B"
        stroke="#E4761B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.8824 39.8886L27.5012 36.7827L28.0095 40.9427L27.953 42.6933L33.8824 39.8886Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1176 39.8886L20.047 42.6933L20.0094 40.9427L20.48 36.7827L14.1176 39.8886Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.141 29.7423L14.8328 28.18L18.5787 26.467L20.141 29.7423Z"
        fill="#233447"
        stroke="#233447"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.84 29.7423L29.4024 26.467L33.1671 28.18L27.84 29.7423Z"
        fill="#233447"
        stroke="#233447"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1176 39.8875L15.0211 32.2451L9.12933 32.4145L14.1176 39.8875Z"
        fill="#CD6116"
        stroke="#CD6116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.9787 32.2451L33.8823 39.8875L38.8705 32.4145L32.9787 32.2451Z"
        fill="#CD6116"
        stroke="#CD6116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.4587 23.8311L26.8799 24.3016L27.8587 29.7416L29.4211 26.4663L33.1858 28.1793L37.4587 23.8311Z"
        fill="#CD6116"
        stroke="#CD6116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8328 28.1793L18.5975 26.4663L20.141 29.7416L21.1387 24.3016L10.541 23.8311L14.8328 28.1793Z"
        fill="#CD6116"
        stroke="#CD6116"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5412 23.8311L14.9835 32.4899L14.8329 28.1793L10.5412 23.8311Z"
        fill="#E4751F"
        stroke="#E4751F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1859 28.1793L32.9977 32.4899L37.4588 23.8311L33.1859 28.1793Z"
        fill="#E4751F"
        stroke="#E4751F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1388 24.302L20.1411 29.742L21.3835 36.1608L21.6658 27.7091L21.1388 24.302Z"
        fill="#E4751F"
        stroke="#E4751F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.88 24.302L26.3717 27.6902L26.5976 36.1608L27.8588 29.742L26.88 24.302Z"
        fill="#E4751F"
        stroke="#E4751F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.8588 29.7425L26.5976 36.1614L27.5011 36.7825L32.9976 32.4908L33.1858 28.1802L27.8588 29.7425Z"
        fill="#F6851B"
        stroke="#F6851B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8328 28.1802L14.9834 32.4908L20.4799 36.7825L21.3834 36.1614L20.141 29.7425L14.8328 28.1802Z"
        fill="#F6851B"
        stroke="#F6851B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9529 42.6931L28.0094 40.9426L27.5388 40.5284H20.4423L20.0094 40.9426L20.0471 42.6931L14.1176 39.8884L16.1882 41.5826L20.3859 44.5002H27.5953L31.8118 41.5826L33.8823 39.8884L27.9529 42.6931Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.5011 36.7808L26.5976 36.1597H21.3835L20.4799 36.7808L20.0093 40.9408L20.4423 40.5267H27.5388L28.0093 40.9408L27.5011 36.7808Z"
        fill="#161616"
        stroke="#161616"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.4001 14.8141L48.0001 7.13412L45.6095 0L27.5013 13.44L34.466 19.3318L44.3107 22.2118L46.4942 19.6706L45.553 18.9929L47.0589 17.6188L45.8918 16.7153L47.3977 15.5671L46.4001 14.8141Z"
        fill="#763D16"
        stroke="#763D16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0 7.13412L1.6 14.8141L0.58353 15.5671L2.08941 16.7153L0.941177 17.6188L2.44706 18.9929L1.50588 19.6706L3.67059 22.2118L13.5153 19.3318L20.48 13.44L2.37177 0L0 7.13412Z"
        fill="#763D16"
        stroke="#763D16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.3106 22.2118L34.4659 19.3318L37.4588 23.8306L32.9976 32.4894L38.8706 32.4141H47.6235L44.3106 22.2118Z"
        fill="#F6851B"
        stroke="#F6851B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5152 19.3318L3.67047 22.2118L0.395172 32.4141H9.12929L14.9834 32.4894L10.5411 23.8306L13.5152 19.3318Z"
        fill="#F6851B"
        stroke="#F6851B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.8799 24.303L27.501 13.4418L30.3622 5.70532H17.6563L20.4799 13.4418L21.1387 24.303L21.3646 27.7289L21.3834 36.1618H26.5975L26.6352 27.7289L26.8799 24.303Z"
        fill="#F6851B"
        stroke="#F6851B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_1768">
        <rect
          width="48"
          height="48"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
