<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M7.125 12C9.81739 12 12 9.81739 12 7.125C12 4.43261 9.81739 2.25 7.125 2.25C4.43261 2.25 2.25 4.43261 2.25 7.125C2.25 9.81739 4.43261 12 7.125 12Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0429 16.4571C15.4334 16.8476 16.0666 16.8476 16.4571 16.4571C16.8476 16.0666 16.8476 15.4334 16.4571 15.0429L15.0429 16.4571ZM11.5821 10.1679L10.875 9.46079L9.46079 10.875L10.1679 11.5821L11.5821 10.1679ZM16.4571 15.0429L11.5821 10.1679L10.1679 11.5821L15.0429 16.4571L16.4571 15.0429Z"
      fill="currentColor"
    />
  </svg>
</template>
