import useAccount from '#composables/use-account'
import useLogin from '#composables/use-login'

import type { RouteLocationNormalized, NavigationGuard } from 'vue-router'

const redirectToCommunityGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  const { communities } = await useAccount()
  const { isAuthReady, isAdmin } = useLogin()

  await isAuthReady()
  // nothing under here will fire until the machine reaches a conclusive state
  // which is the user being logged in
  const isOwner = communities.value?.filter(community => community.role === 'owner' && community.community.slug === to.params.id)

  if (!isOwner?.length && !isAdmin.value) {
    return `/${to.params.id}`
  }
}

export default [
  {
    path: '/community/:id/edit',
    name: 'communityUserEdit',
    redirect: (route: RouteLocationNormalized) => ({ name: 'profile-edit', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
    props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase(), rewardId: route.params.rewardId as string }),
  },
  {
    path: '/community/:id',
    name: 'community',
    redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
    props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
    children: [
      {
        path: 'community-rewards',
        name: 'communityRewards',
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile-drops', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
      },
      {
        path: 'community-shop',
        name: 'communityShop',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
      {
        path: 'community-links',
        name: 'communityLinks',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
      {
        path: 'community-hub',
        name: 'communityHub',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
    ],
  },
  {
    path: '/community/:id/reward/create-reward',
    name: 'communityRewardCreate',
    redirect: (route: RouteLocationNormalized) => ({ name: 'profileDropCreate', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
    beforeEnter: redirectToCommunityGuard,
  },
  {
    path: '/community/:id/reward/:rewardId/edit',
    name: 'communityRewardEdit',
    beforeEnter: redirectToCommunityGuard,
    redirect: (route: RouteLocationNormalized) => ({ name: 'drop-edit', params: { profileSlug: (route.params.id as string).toLowerCase(), dropId: (route.params.rewardId as string) } }),
  },
  {
    path: '/community/:id/reward/:rewardId/add-benefit',
    name: 'communityRewardAddBenefit',
    redirect: (route: RouteLocationNormalized) => ({ name: 'drop-add-benefit', params: { profileSlug: (route.params.id as string).toLowerCase(), dropId: (route.params.rewardId as string) } }),
  },
  {
    path: '/community/:id/reward/:rewardId/upload-benefit',
    name: 'communityRewardUploadBenefitFile',
    redirect: (route: RouteLocationNormalized) => ({ name: 'drop-upload-perk-file', params: { profileSlug: (route.params.id as string).toLowerCase(), dropId: (route.params.rewardId as string) } }),
  },
  {
    path: '/community/:id/reward/:rewardId/:tokenId',
    name: 'communityRewardToken',
    redirect: (route: RouteLocationNormalized) => ({ name: 'drop-token', params: { profileSlug: (route.params.id as string).toLowerCase(), dropId: route.params.rewardId as string, tokenId: route.params.tokenId } }),
  },
  {
    path: '/community/:id/post/:postId',
    name: 'old-sharedPost',
    redirect: (route: RouteLocationNormalized) => ({ name: 'sharedPost', params: { profileSlug: (route.params.id as string).toLowerCase(), postId: route.params.postId as string } }),
  },
  {
    path: '/community/:id/reward/:rewardId',
    name: 'reward',
    redirect: (route: RouteLocationNormalized) => ({ name: 'drop', params: { profileSlug: (route.params.id as string).toLowerCase(), dropId: route.params.rewardId as string } }),
  },
  {
    path: '/:id',
    name: 'communityRedirect',
    redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
    props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
    children: [
      {
        path: 'community-rewards',
        name: 'communityRewardsRedirect',
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile-drops', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
      },
      {
        path: 'community-shop',
        name: 'communityShopRedirect',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
      {
        path: 'community-links',
        name: 'communityLinksRedirect',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
      {
        path: 'community-hub',
        name: 'communityHubRedirect',
        redirect: (route: RouteLocationNormalized) => ({ name: 'profile', params: { profileSlug: (route.params.id as string).toLowerCase() } }),
        props: (route: RouteLocationNormalized) => ({ id: (route.params.id as string).toLowerCase() }),
      },
    ],
  },
]
