<template>
  <h1
    v-if="level === 0"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-3xl md:text-4xl lg:text-6xl',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h1>

  <h1
    v-if="level === 1"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-4xl',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h1>

  <h2
    v-if="level === 2"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-2xl md:text-3xl',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h2>

  <h3
    v-if="level === 3"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-2xl',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h3>

  <h4
    v-if="level === 4"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-base md:text-xl',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h4>

  <h5
    v-if="level === 5"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : 'text-lg',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h5>

  <h6
    v-if="level === 6"
    v-bind="$attrs"
    :class="[
      textSize ? textSize : ' text-base',
      fontWeight,
      textColor
    ]"
  >
    <slot />
  </h6>
</template>

<script setup lang="ts">
import { toRefs, computed } from 'vue'

import type { PropType } from 'vue'
import type { TypeElementType, LevelType } from '#components/typography/heading/types'

const props = defineProps({
  level: {
    type: Number as PropType<LevelType>,
    default: 1,
    validator: (level: LevelType) => level >= 0 && level <= 6,
  },

  titleElement: {
    type: String as PropType<TypeElementType>,
    default: '',
    validator: (element: TypeElementType) => [
      'display-title',
      'hero-title',
      'leading-title',
      'section-title',
      'section-subtitle',
      'item-title',
      'small-title',
      'full-base-title',
      '',
    ].includes(element),
  },

  darkBackground: {
    type: Boolean,
    default: false,
  },

  blackText: {
    type: Boolean,
    default: false,
  },
})

const { level } = toRefs(props)

const textSize = computed(() => typographyElementTextSize() ? typographyElementTextSize() : '' )
const fontWeight = computed(() => props.titleElement === 'leading-title' ? 'font-normal' : 'font-medium')
const textColor = computed(() => {
  switch (props.titleElement) {
    case 'display-title':
    case 'section-title':
    case 'section-subtitle':
    case 'item-title':
    case 'hero-title':
    case 'small-title':
    default:
      return props.darkBackground ? 'text-white' : 'text-black'
    case 'leading-title':
      return props.darkBackground ? 'text-brand-border' : props.blackText ? 'text-black' : 'text-brand-copy-light'
  }
})

const typographyElementTextSize = () => {
  switch (props.titleElement) {
    case 'display-title':
      return 'text-6xl md:text-8xl lg:text-9xl'
    case 'hero-title':
      return 'text-3xl md:text-4xl lg:text-6xl'
    case 'leading-title':
      return 'text-base md:text-xl lg:text-3xl'
    case 'section-title':
      return 'text-xl md:text-2xl lg:text-3xl'
    case 'section-subtitle':
      return 'text-lg md:text-xl lg:text-2xl'
    case 'item-title':
      return 'text-base md:text-base lg:text-xl'
    case 'small-title':
      return 'text-xs md:text-xs lg:text-base'
    case 'full-base-title':
      return 'text-base'
    default:
      return ''
  }
}
</script>
