import { ref } from 'vue'

import { Amplify } from 'aws-amplify'
import { getCurrentUser, signIn, signOut, confirmSignIn } from 'aws-amplify/auth'

import { useSubscription, provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '#/client'
import { trackCustomMetric, setHeapIdentity } from '#utils/heap'

import { useLazyQuery, useMutation } from '#composables/use-apollo'

import { GET_ACCOUNT } from '#queries/GetAccount'
import { LOGIN_BY_EMAIL } from '#mutations/LoginByEmail'
import { LOGIN_BY_WALLET } from '#mutations/LoginByWallet'
import { LOGIN_BY_FARCASTER } from '#mutations/LoginByFarcaster'
import { CREATE_ACCOUNT } from '#mutations/CreateAccount'
import { UPDATE_ACCOUNT } from '#mutations/UpdateAccount'
import { ON_ACCOUNT_UPDATED } from '#subscriptions/OnAccountUpdated'

const cognitoPoolId = import.meta.env.VITE_COGNITO_POOL_ID
const cognitoWebClientId = import.meta.env.VITE_COGNITO_WEB_CLIENT_ID

export const isLoadingAccount = ref(false)

export const setupCognito = async () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        region: 'us-east-1',
        userPoolId: cognitoPoolId,
        userPoolClientId: cognitoWebClientId,
      },
    },
  })
}

const { load: loadAccount } = useLazyQuery(GET_ACCOUNT)
const { load: loginByEmail } = useMutation(LOGIN_BY_EMAIL)
const { load: loginByWallet } = useMutation(LOGIN_BY_WALLET)
const { load: loginByFarcaster } = useMutation(LOGIN_BY_FARCASTER)
const { load: createAccount } = useMutation(CREATE_ACCOUNT)
const { load: updateAccount } = useMutation(UPDATE_ACCOUNT)

export const createEmailAccount = async (email) => {
  try {
    const { data: { createAccount: { id } } } = await createAccount({ input: { email } })

    return id
  } catch (error) {
    const code = error.graphQLErrors[0].extensions.code

    if (code === 'REMX_ACCOUNT_EMAIL_IN_USE') {
      throw new Error(`REMX_ACCOUNT_EMAIL_IN_USE:${error.message}`)
    } else {
      throw new Error('An account with this email address already exists.')
    }
  }
}

export const createWalletAccount = async (address, connectionType) => {
  try {
    const { data: { createAccount: { id } } } = await createAccount({ input: { address: address.toLowerCase(), connectionType } })

    return id
  } catch (error) {
    const code = error.graphQLErrors[0].extensions.code

    if (code === 'REMX_ACCOUNT_ADDRESS_IN_USE') {
      throw new Error(`REMX_ACCOUNT_ADDRESS_IN_USE:${error.message}`)
    } else {
      console.error('createWalletAccount error', error)
      throw new Error('An account with this wallet already exists.')
    }
  }
}

export const createFarcasterAccount = async (address, connectionType, fid, farcasterUsername) => {
  try {
    const { data: { createAccount: { id } } } = await createAccount({ input: { address: address.toLowerCase(), connectionType, fid, farcasterUsername } })

    return id
  } catch (error) {
    const code = error.graphQLErrors[0].extensions.code

    if (code === 'REMX_ACCOUNT_ADDRESS_IN_USE') {
      throw new Error(`REMX_ACCOUNT_ADDRESS_IN_USE:${error.message}`)
    } else {
      console.error('createWalletAccount error', error)
      throw new Error('An account with this wallet already exists.')
    }
  }
}

export const emailLogin = async (email) => {
  try {
    const { data: { loginByEmail: { id } } } = await loginByEmail({ email })

    return id
  } catch (error) {
    const code = error.graphQLErrors[0].extensions.code

    if (code === 'REMX_ACCOUNT_NOT_FOUND') {
      throw new Error('An account with this email address was not found.')
    } else {
      console.error('Unknown emailLogin error', error)
      throw new Error('An unknown error occurred.')
    }
  }
}

export const walletLogin = async (address, connectionType) => {
  try {
    let id
    if (connectionType === 'Farcaster') {
      const { data: { loginByFarcaster: { id :farcasterId } } } = await loginByFarcaster({ address: address.toLowerCase() })
      id = farcasterId
    }
    else {
      const { data: { loginByWallet: { id: walletId } } } = await loginByWallet({ address: address.toLowerCase(), connectionType })
      id = walletId
    }
    
    return id
  } catch (error) {
    const code = error.graphQLErrors[0].extensions.code
    if (code === 'REMX_ACCOUNT_NOT_FOUND') {
      throw new Error('An account with this wallet address was not found.')
    } else {
      console.error('Unknown walletLogin error', error)
      throw new Error('An unknown error occurred.')
    }
  }
}

export const detectCognitoUser = async () => {
  try {
    const { username, signInDetails } = await getCurrentUser()

    isLoadingAccount.value = true

    const { data: { getAccount } } = await loadAccount({ id: username }, { fetchPolicy: 'network-only' })
    const account = getAccount

    isLoadingAccount.value = false

    setHeapIdentity(username)
    trackCustomMetric(
      'ActiveUser',
      {
        accountId: username,
      }
    )

    return {
      signInDetails,
      account,
    }
  } catch (error) {
    throw new Error('No user found.')
  }
}

// This function verifies the user's authentication code
export const authVerify = async (user, answer) => {
  // Send the code to AWS Cognito for verification
  const { isSignedIn } = await confirmSignIn({ challengeResponse: answer } )

  if (!isSignedIn) {
    // Throw an error if the verification code is invalid
    throw new Error('Invalid verification code, a new code has been sent.')
  } else {
    // Load the user account from Hasura
    isLoadingAccount.value = true
    const { data: { getAccount } } = await loadAccount({ id: user.username }, { fetchPolicy: 'network-only' })
    isLoadingAccount.value = false
    const account = getAccount

    // Return the user and account data
    return {
      user: await getCurrentUser(),
      account,
    }
  }
}

export const authSignIn = async (username) => {
  const { isSignedIn, nextStep } = await signIn({ username, options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } })

  if (isSignedIn) {
    return await getCurrentUser()
  }

  return { username: nextStep.additionalInfo.USERNAME, challengeParam: { message: nextStep.additionalInfo.message } }
}

export const authSignOut = async () => {
  try {
    await signOut({ global: true })
  } catch (error) {
    console.error(error)
  }
}

export const accountUpdate = async (id, data) => {
  const input = Object.assign({}, data, { id })
  const { data: { updateAccount: account } } = await updateAccount({ input })

  return { account }
}

export const subscribeToAccount = async (id, send) => {
  try {
    const result = provideApolloClient(apolloClient)(
      () => {
        const {
          onResult,
          restart,
          stop,
        } = useSubscription(ON_ACCOUNT_UPDATED, {
          id,
        })
        onResult(async () => {
          const { data: { getAccount: account } } = await loadAccount({ id }, { fetchPolicy: 'network-only' })
          send({ type: 'SET_ACCOUNT', data: { account } })
        })
        return {
          restart,
          stop,
        }
      }
    )

    return result
  } catch (error) {
    console.error('startActivityMonitor', error)
    throw error
  }
}
