import {
  account,
  accountId,
  authService,
  challengeMessage,
  email,
  fetchAccount,
  id,
  isAdmin,
  isAuthenticated,
  isLoading,
  isLoadingAccount,
  logout,
  reset,
  send,
  state,
  verified,
} from './login.machine'

import { detectCognitoUser } from './login.service'

let sub

const isAuthReady = () => {
  return new Promise((resolve) => {
    // return earlier if user is already authenticated
    if (isAuthenticated.value) {
      resolve(true)
    }

    // otherwise wait for machine to transition
    sub = authService.subscribe(state => {
      if (state.matches('loggedIn.idle')) {
        sub?.unsubscribe()

        resolve(true)
      }
    })
  })
}

export default function useLogin () {
  return {
    authService,
    state,
    send,
    isAuthenticated,
    account,
    isLoading,
    logout,
    challengeMessage,
    accountId,
    isLoadingAccount,
    isAuthReady,
    isAdmin,
    id,
    verified,
    reset,
    email,
    updateAccount: (data) => { send({ type: 'UPDATE_ACCOUNT', data })},
    detectCognitoUser,
    fetchAccount,
  }
}
