import { gql } from '@apollo/client/core'

export const ACCOUNT_ACTIVITY = gql`
  subscription AccountActivity($id: ID!) {
    accountActivity(id: $id) {
      __typename
      ... on TokenPurchasePending {
        id
        address
        collectionId
        collectionName
        collectionType
        communitySlug
        tokenId
      }
      ... on TokenPurchaseConfirming {
        id
        address
        collectionId
        collectionName
        collectionType
        communitySlug
        tokenId
        transactionHash
      }
      ... on TokenPurchaseSuccess {
        id
        address
        collectionId
        collectionName
        collectionType
        communitySlug
        tokenId
        transactionHash
      }
      ... on TokenPurchaseFailed {
        id
        address
        collectionId
        collectionName
        collectionType
        communitySlug
        tokenId
        transactionHash
      }
      ... on TokenRenderComplete {
        id
        address
        collectionId
        collectionName
        collectionType
        communitySlug
        tokenId
        transactionHash
      }
      ... on TokenTransfer {
        id
        address
        collectionId
        collectionName
        tokenId
        transactionHash
        fromAccount
        toAccount
      }
      ... on JoinCommunity {
        id
        communityId
      }
      ... on LeaveCommunity {
        id
        communityId
      }
    }
  }
`
