<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[
      spin ? 'animate-spin' : '',
      loaderSize,
    ]"
  >
    <path
      d="M12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92894C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.76121C17.6541 3.51809 19.2159 4.79981 20.3147 6.4443C21.4135 8.0888 22 10.0222 22 12"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      :class="[isDark ? 'stroke-black' : isTertiary ? 'stroke-[#4f46e5]' : '']"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { PropType } from 'vue'

type LoaderSize =
  | 'sm'
  | 'md'
  | 'lg'

const props = defineProps({
  isDark: {
    type: Boolean,
    default: false,
  },

  isTertiary: {
    type: Boolean,
    default: false,
  },

  spin: {
    type: Boolean,
    default: true,
  },

  size: {
    type: String as PropType<LoaderSize>,
    default: 'sm',
  },
})

const loaderSize = computed(() => {
  switch (props.size) {
    case 'sm':
    default:
      return 'w-4 h-4'
    case 'md':
      return 'w-10 h-10'
    case 'lg':
      return 'w-14 h-14'
  }
})
</script>
