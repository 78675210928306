<template>
  <div
    v-if="activity.length"
    id="account-activity-widget"
    class="hidden px-5 md:pl-10 mb-5 md:mb-10 w-full md:w-96 exclude"
  >
    <div
      class="w-full bg-black text-white rounded-lg transition-all duration-300 ease-in overflow-hidden p-6"
      :class="[expanded ? `max-h-[calc(140px + 40px*3)]` : 'max-h-20']"
    >
      <div class="grid grid-cols-[15%_minmax(70%,_1fr)_15%] justify-center grid-flow-dense gap-x-2 items-center">
        <transition
          enter-active-class="transition ease-out"
          enter-from-class="transform scale-0 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-0 opacity-0"
          mode="out-in"
        >
          <CheckIcon
            v-if="isAllCompleted"
            class="w-6 h-6 justify-self-center"
          />

          <ShoppingCartIcon
            v-else
            class="justify-self-center"
          />
        </transition>

        <label
          for="account-activity-widget-btn"
          class="cursor-pointer"
        >
          <div>
            <Body
              size="sm"
              white-text
            >
              {{ title }}
            </Body>

            <transition
              enter-active-class="transition ease-out"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition delay-150"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
              mode="out-in"
            >
              <Body
                v-if="expanded"
                size="xs"
                dark-background
              >
                Collapse to hide details
              </Body>
            </transition>
          </div>
        </label>

        <button
          id="account-activity-widget-btn"
          class="justify-self-center self-start h-min focus:outline-white focus:outline-4 hover:border-brand-accent-light focus:hover:outline-brand-accent-light"
          :aria-expanded="expanded"
          aria-controls="account-activity-widget"
          @click="expanded = !expanded"
        >
          <span class="sr-only">
            {{ expanded ? 'hide account widget' : 'show account widget' }}
          </span>

          <ChevronDownIcon
            v-if="expanded"
            class="w-6 h-6"
          />

          <ChevronUpIcon
            v-else
            class="w-6 h-6"
          />
        </button>
      </div>

      <transition
        leave-active-class="transition delay-150"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        mode="out-in"
      >
        <div
          v-if="expanded"
          class="flex flex-col gap-y-2 pt-4"
        >
          <TransitionGroup name="list">
            <div
              v-for="item in activity"
              :key="`${item.collectionId}${item.tokenId}`"
              class="grid grid-cols-[15%_minmax(70%,_1fr)_15%] gap-x-2 justify-center grid-flow-dense"
            >
              <div />

              <div class="flex items-center last:items-start">
                <div
                  class="cursor-pointer"
                  @click="item.__typename === 'TokenPurchaseSuccess' || item.__typename === 'TokenPurchaseConfirming'
                    ? navigateToNft(item.collectionId, item.collectionType, item.communitySlug, item.tokenId)
                    : item.__typename !== 'TokenPurchasePending' && item?.transactionHash
                      ? openEtherscan({
                        hash:item.transactionHash || '',
                        collectionId: item.collectionId,
                        tokenId: item.tokenId,
                        removeItem: true,
                      })
                      : null
                  "
                >
                  <Body
                    size="sm"
                    white-text
                    :class="item.__typename === 'TokenPurchaseFailed' && 'opacity-50'"
                  >
                    {{ item.collectionName }} #{{ item.tokenId }}
                  </Body>

                  <Body
                    size="xs"
                    dark-background
                  >
                    {{ formatAction(item.__typename) }}
                  </Body>
                </div>
              </div>

              <button
                v-if="item.__typename === 'TokenPurchaseSuccess' || item.__typename === 'TokenPurchaseConfirming'"
                class="justify-self-center h-min focus:outline-white focus:outline-4 hover:border-brand-accent-light focus:hover:outline-brand-accent-light"
                @click="navigateToNft(item.collectionId, item.collectionType, item.communitySlug, item.tokenId)"
              >
                <ChevronRightIcon class="w-6 h-6" />
              </button>

              <button
                v-else-if="item.__typename !== 'TokenPurchasePending' && item?.transactionHash"
                class="justify-self-center h-min focus:outline-white focus:outline-4 hover:border-brand-accent-light focus:hover:outline-brand-accent-light"
                @click="openEtherscan({
                  hash:item.transactionHash || '',
                  ...(item.__typename === 'TokenPurchaseFailed' && {
                    collectionId: item.collectionId,
                    tokenId: item.tokenId,
                    removeItem: true,
                  }),
                })"
              >
                <InfoIcon class="w-6 h-6" />
              </button>
            </div>
          </TransitionGroup>

          <div class="pt-4">
            <button
              @click="clearActivities()"
            >
              <Body
                size="xs"
                dark-background
                class="underline underline-offset-2"
              >
                Remove summary
              </Body>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, type Ref } from 'vue'

import useLogin from '#composables/use-login'
import useProviders from '#composables/use-providers'
import useSnackbar from '#composables/use-snackbar'
import { useRouter } from 'vue-router'
import { useSubscription } from '@vue/apollo-composable'

import { ACCOUNT_ACTIVITY } from '#subscriptions/AccountActivity'
import type {
  AccountActivity,
  AccountActivityVariables,
  AccountActivity_accountActivity,
  AccountActivity_accountActivity_TokenPurchaseConfirming,
  AccountActivity_accountActivity_TokenPurchaseFailed,
  AccountActivity_accountActivity_TokenPurchasePending,
  AccountActivity_accountActivity_TokenPurchaseSuccess,
} from '#graphql/types/AccountActivity'

import { CheckIcon, ChevronDownIcon, ChevronUpIcon, InfoIcon, ChevronRightIcon } from '@creativelayer/chalk-icons'
import Body from '#components/typography/body'
import ShoppingCartIcon from '#components/icons/shopping-cart-icon.vue'
import LoadingIcon from '#components/icons/loading-icon.vue'

const { accountId } = useLogin()
const { addSnack, removeSnack } = useSnackbar()
const { currentChainInfo } = useProviders()
const router = useRouter()

const activity:Ref<(
  AccountActivity_accountActivity_TokenPurchaseConfirming
  | AccountActivity_accountActivity_TokenPurchaseFailed
  | AccountActivity_accountActivity_TokenPurchasePending
  | AccountActivity_accountActivity_TokenPurchaseSuccess
)[]> = ref([])

const expanded = ref(false)

const isAllCompleted = computed(() => activity.value.every(item => item.__typename === 'TokenPurchaseSuccess' || item.__typename === 'TokenPurchaseFailed'))

const title = computed(() => {
  if (activity.value.length === 0) {
    return
  }
  /* Commenting out this logic for now incase we want to come back to this in the future */
  // if (isAllCompleted.value) {
  //   return 'Minting complete'
  // }

  // const inProgress = activity.value.filter(item => item.__typename === 'TokenPurchasePending' || item.__typename === 'TokenPurchaseConfirming')?.length

  // if (inProgress && activity.value.length > 1) {
  //   return `Minting ${inProgress}/${activity.value.length}`
  // }

  return 'Transaction summary'
})

const formatAction = (action: AccountActivity_accountActivity['__typename']) => {
  switch (action) {
    case 'TokenPurchasePending':
    default:
      return 'Initiated purchase'
    case 'TokenPurchaseConfirming':
      return 'Verifying mint'
    case 'TokenPurchaseFailed':
      return 'Incomplete'
    case 'TokenPurchaseSuccess':
      return 'Complete'
  }
}

const variables = ref({
  id: accountId.value,
})

const {
  onResult,
  restart,
  stop,
} = useSubscription<AccountActivity, AccountActivityVariables>(ACCOUNT_ACTIVITY, variables.value)

onResult((res) => {
  const data = res?.data?.accountActivity

  if (data) {
    const { __typename } = data

    // type narrowing to what's token-related only
    // won't accept array.includes
    if (__typename === 'TokenPurchaseConfirming'
      || __typename === 'TokenPurchasePending'
      || __typename === 'TokenPurchaseFailed'
      || __typename === 'TokenPurchaseSuccess') {
      if (__typename === 'TokenPurchaseSuccess') {
        const { collectionName, tokenId } = data

        setTimeout(() => {
          removeActivityItem(collectionId, tokenId)
        }, 30000)
      }

      if (__typename === 'TokenPurchaseFailed') {
        const { collectionId, tokenId, transactionHash } = data
        addSnack({ message: `Shoot. We could not mint your item because of a problem on the blockchain. You will not be charged. Please try again.`, color: 'negative', action: () => openEtherscan({ hash: transactionHash || '', removeItem: true, collectionId, tokenId }), actionLabel: 'View details' })
      }

      // if (__typename === 'TokenPurchaseConfirming') {
      //   console.log('data', data)
      //   const { collectionId, tokenId, collectionType, communitySlug } = data

      //   addSnack({ message: `We're verifying your item. Feel free to explore and track your item in the transaction summary.`, color: 'positive', time: 100000, action: (_, snackId) => navigateToNft(collectionId, collectionType, communitySlug, tokenId, snackId as string), actionLabel: 'View item' })
      // }

      const { collectionId, tokenId } = data
      const existingItem = activity.value.find(item => item.collectionId === collectionId && item.tokenId === tokenId)

      if (existingItem) {
        activity.value[activity.value.indexOf(existingItem)] = data
      } else {
        activity.value.push(data)
      }
    }
  }
})

watch(accountId, (newAccountId) => {
  if (newAccountId) {
    variables.value.id = newAccountId
    restart()
  } else {
    variables.value.id = ''
    stop()
  }
  activity.value = []
})


const removeActivityItem = (collectionId: string, tokenId: number) => {
  const match = activity.value.find(item => item.collectionId === collectionId && item.tokenId === tokenId)
  if (match) {
    const index = activity.value.indexOf(match)
    activity.value.splice(index, 1)
  }
}

const openEtherscan = (options: { hash: string, removeItem?: boolean, collectionId?: string, tokenId?: number }) => {
  const { hash, removeItem, collectionId, tokenId } = options
  if (removeItem  && collectionId && tokenId ) {
    removeActivityItem(collectionId, tokenId)
  }

  window.open(`${currentChainInfo.value.url}/tx/${hash}`, '_blank')
}

const navigateToNft = (collectionId: string, collectionType: string, communitySlug: string, tokenId: number,  snackId?: string) => {
  // removeActivityItem(collectionId, tokenId)
  if (collectionType === 'Single') {
    router.push({ name: 'communityRewardToken', params: { id: communitySlug, rewardId: collectionId, tokenId } })
  }

  if (collectionType === 'REMX') {
    router.push({ name: 'token', params: { collectionId, tokenId } })
  }

  if (snackId) {
    removeSnack(snackId)
  }
}

const clearActivities = () => {
  activity.value = []
}
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
</style>
