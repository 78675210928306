<template>
  <button
    class="group flex flex-row gap-2 md:gap-3 items-center text-left w-full pt-4"
    @click.prevent="navigateToProfile"
    @click.stop
  >
    <div class="aspect-square rounded-full bg-brand-border w-8 h-8 md:w-12 md:h-12 overflow-hidden">
      <img
        v-if="icon"
        class="aspect-square w-8 h-8 md:w-12 md:h-12 group-hover:scale-110 transition-transform duration-300"
        :src="icon ? getImage(icon as string, {resize: {width: 128, height: 128, fit: 'cover'}}) : ''"
        alt="account image"
      >

      <img
        v-else
        class="h-9 w-9 md:w-12 md:h-12"
        :src="BlankProfileIcon"
      >
    </div>
          
    <div class="grow w-0">
      <Body
        size="sm"
        class="font-medium truncate"
        black-text
      >
        {{ displayName }}
      </Body>
          
      <Body
        size="xs"
        class="truncate"
      >
        @{{ slug }}
      </Body>
    </div>
  </button>
</template>
        
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

import useImage from '#composables/use-image'
    
import Body from '#components/typography/body'

import BlankProfileIcon from '#/assets/default_avatar.png'
        
defineProps({
  icon: {
    type: String,
    required: true,
  },
        
  displayName: {
    type: String,
    required: true,
  },  
        
  slug: {
    type: String,
    required: true,
  },
        
})
          
const emit = defineEmits(['navigate'])
    
const { getImage } = useImage()
          
const navigateToProfile = () => {
  emit('navigate')
}
</script>
